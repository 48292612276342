<template>
  <div class="siffaPageBottom">
    <div class="content_area">
      <div class="contact_follow">
        <div class="contact">
          <h2>联系我们</h2>
          <ul>
            <li v-for="(item, i) in contact_arr" :key="i">
              {{ item.lable }}{{ item.content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      Copyright@2021 青岛市国际物流商会 版权所有
      <a href="https://ailaworld.com.cn/">AiLaworld.com</a>
    </div>
  </div>
</template>

<script>
import bottomContent from "~qlc/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      Links_arr: [
        {
          lable: "上海海关",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "科越信息",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
        {
          lable: "上海出入境检验检疫局",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
      ],
      contact_arr: [
        {
          lable: "姓名：",
          content: "李萌",
        },
        {
          lable: "地址：",
          content: "青岛市市南区香港中路18号福泰广场B座1604",
        },
        {
          lable: "邮编：",
          content: "266071",
        },
        {
          lable: "电话：",
          content: "13375320398",
        },
        {
          lable: "E-mail：",
          content: "QILCC2020@163.COM",
        },
      ],
      QRcode_arr: [
        {
          name: "微信",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/plc/16408637c513e27dc2ffec985eb80f3.jpg",
        },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style  scoped lang="less">
.siffaPageBottom {
  // margin-top: 24px;
  width: 100%;
  background: #fff;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #999;
  }
  .link_box {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 24px;
      // width: 64px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
  .contact_follow {
    padding-top: 42px;
    display: flex;
    h2 {
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #666666;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 1070px;
      height: 220px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }
    }
    .follow {
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #ececec;
    height: 33px;
    line-height: 33px;
    color: #666;
    font-size: 12px;
    a {
      color: #666;
      font-size: 12px;
    }
  }
}
</style>
