import Vue from 'vue'
import {baseConsoleRoutes} from '~bac/router'

Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [{
		path: '/test',                                              //首页
		name: 'test',
		component: resolve => require(['~qlc/views/test'], resolve),
	},
		{
			path: '/',                                              //首页
			name: 'index',
			component: resolve => require(['~qlc/views/index'], resolve),

		},
		{
			path: '/downloadSection',                                              //首页
			name: 'downloadSection',
			component: resolve => require(['~qlc/views/index/downloadSection/index'], resolve),
		},
		{
			path: '/education_index',                                              //教育培训
			name: 'education_index',
			component: resolve => require(['~qlc/views/education/index'], resolve),
		},
		{
			path: '/talents/positionList',                                  //人才中心
			name: 'positionList',
			component: resolve => require(['~qlc/views/position/list'], resolve)
		},
		{
			path: '/talents/library',                                   // 人才库
			name: 'library',
			component: resolve => require(['~qlc/views/talents/library'], resolve)
		},
		{
			path: '/positionDetail',                                //职位详情
			name: 'positionDetail',
			component: resolve => require(['~qlc/views/position/detail'], resolve)
		},
		{
			path: '/education_detail',                                              //教育培训--详情
			name: 'education_detail',
			component: resolve => require(['~qlc/views/education/detail'], resolve),
		},
		{
			path: '/activitys',                                              //首页--活动
			name: 'activitys',
			component: resolve => require(['~qlc/views/index/activitys/index'], resolve),
			children: []
		},
		{
			path: '/memberShip',                                              //申请入会
			name: 'memberShip',
			component: resolve => require(['~qlc/views/index/memberShip/index'], resolve),
			children: [
				{
					path: '/',
					name: 'applyFor',
					component: resolve => require(['~qlc/views/index/memberShip/flow'], resolve)
				}, {
					path: '/memberShip/applyFor',
					name: 'applyFor',
					component: resolve => require(['~qlc/views/index/memberShip/applyFor'], resolve)
				}]
		},
		{
			path: '/survey',                                              //协会概况
			name: 'survey',
			component: resolve => require(['~qlc/views/survey'], resolve),
			children: [{
				path: '/',
				name: 'brief',
				component: resolve => require(['~qlc/views/survey/brief'], resolve)
			}, {
				path: '/survey/director',
				name: 'director',
				component: resolve => require(['~qlc/views/survey/director'], resolve)
			}, {
				path: '/survey/framework',
				name: 'framework',
				component: resolve => require(['~qlc/views/survey/framework'], resolve)
			}, {
				path: '/survey/leader',
				name: 'leader',
				component: resolve => require(['~qlc/views/survey/leader'], resolve)
			}, {
				path: '/survey/supervisor',
				name: 'supervisor',
				component: resolve => require(['~qlc/views/survey/supervisor'], resolve)
			}, {
				path: '/survey/member',
				name: 'member',
				component: resolve => require(['~qlc/views/survey/member'], resolve)
			}, {
				path: '/survey/constitution',
				name: 'constitution',
				component: resolve => require(['~qlc/views/survey/constitution'], resolve)
			}, {
				path: "/survey/memorabilia",
				name: 'memorabilia',
				component: resolve => require(['~qlc/views/survey/memorabilia'], resolve)
			}]
		}, {
			path: '/companyCatalog',//会员名录
			name: 'companyCatalog',
			component: resolve => require(['~qlc/views/company/companyCatalog'], resolve)
		}, {
			path: '/companyContactList',
			name: 'companyContactList',
			component: resolve => require(['~qlc/views/company/companyContactList'], resolve)
		}, {
			path: '/companyDetail',
			name: 'companyDetail',
			component: resolve => require(['~qlc/views/company/companyDetail'], resolve)
		}, {
			path: '/sign',
			name: 'sign',
			component: resolve => require(['~qlc/views/users/sign'], resolve),
			meta: {
				layout: 'sign'
			}
		},
		{
			path: '/forgetPassword',
			name: 'forgetPassword',
			component: resolve => require(['~qlc/views/users/forgetPassword'], resolve),
		},
		{
			path: '/resetPassword',
			name: 'resetPassword',
			component: resolve => require(['~qlc/views/users/resetPassword'], resolve),
		},
		{
			path: '/signUp',
			name: 'signUp',
			component: resolve => require(['~qlc/views/users/signUp'], resolve),
			meta: {
				layout: 'sign'
			}
		}, {
			path: '/activeAccount',
			name: 'activeAccount',
			component: resolve => require(['~qlc/views/users/activeAccount'], resolve),
		}, {
			path: '/memberCenter',
			name: 'memberCenter',
			component: resolve => require(['~qlc/views/memberCenter'], resolve)
		},
		{
			path: "/creditAssessment",//信用评估页面
			name: "creditAssessment",
			component: resolve => require(['~qlc/views/creditAssessment'], resolve)
		},
		{
			path: "/creditDetail",//信用评估详情页面
			name: "creditDetail",
			component: resolve => require(['~qlc/views/creditAssessment/detail'], resolve)
		},
		{
			path: "/basicInfo",//完善信息
			name: "basicInfo",
			component: resolve => require(['~qlc/views/users/basicInfo'], resolve)
		},
		{
			path: "/contactUs",//完善信息
			name: "contactUs",
			component: resolve => require(['~qlc/views/contactUs/index'], resolve)
		},
		{
			path: "/detail",//完善信息
			name: "detail",
			component: resolve => require(['~qlc/views/index/detail'], resolve)
		},
		{
			path: "/memberInfo",
			name: "memberInfo",
			component: resolve => require(['~qlc/views/index/detail/companyInfo'], resolve)
		},
		{
			path: '/handleInitialParams',
			name: 'handleInitialParams',
			meta: {
				layout: 'empty'
			},
			component: resolve => require(['~qlc/views/users/initPage'], resolve)
		},
		{
			path: "/overseas",//直通海外
			name: "overseas",
			component: resolve => require(['@/baseComponents/baseInquiry/overseas/index'], resolve)
		},
		{
			path: "/publishInquiry",//一键发盘
			name: "publishInquiry",
			component: resolve => require(['@/baseComponents/baseInquiry/publishInquiry/index'], resolve)
		},
		{
			path:'/activity/list',
			name:'activityList',
			component:resolve => require(['~qlc/views/activity/list'],resolve)
		},
		{
			path:'/activity/detail',
			name:'activetyDetail',
			component:resolve => require(['~qlc/views/activity/detail'],resolve)
		}
	].concat(baseConsoleRoutes).concat([{
		path: "**",
		redirect: "/"
	}]),
	scrollBehavior(to, from, savedPosition) {
		return {x: 0, y: 0}
	}
})
