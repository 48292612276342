import { commonEnumFormat } from '~/basePlugins/filters'

import { creditClass } from '~qlc/utils/enumValue'                 //信用等级
export const creditClassFormat = commonEnumFormat(creditClass)

import { unitType } from '~qlc/utils/enumValue'                 //单位类型
export const unitTypeFormat = commonEnumFormat(unitType)

import { getCityNameById } from '@/baseUtils/AddressConfig'
export const getCityNameByCityId = getCityNameById//根据城市id获取城市名

import { yearList } from '~qlc/utils/enumValue'                 //单位类型
export const yearListFormat = commonEnumFormat(yearList)
import { staffsizeList } from '~qlc/utils/enumValue'                 //信用等级
export const staffsizeFormat = commonEnumFormat(staffsizeList);
